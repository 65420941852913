/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
body {
    padding: 0rem;
}
.mat-card {
    text-align: center;
    background-color: #f48fb1;
    color: white;
    margin-bottom: 0.5rem;
}
.mat-card-title {
    color: white;
}

.mat-card-subtitle {
    color: white;
}

.mat-form-field {
    width: 100%;
}

.mat-button {
    font-weight: 400;
}

.mat-raised-button {
    font-weight: 400;
    font-size: 1.0em;
    background-color: #f48fb1;
    color: white;
}

button.mat-flat-button {
    margin-left: 0.5rem;
    font-weight: 400;
    font-size: 1.0em;
}

button.mat-flat-button.mat-primary {
    color: white;
    background-color: #f48fb1;
}

button.mat-flat-button.mat-primary.first {
    margin-left: unset;
}

button.mat-flat-button.mat-primary.yes {
    color: white;
    background-color: #f48fb1;
}

button.mat-flat-button.mat-primary.yes:disabled {
    color: gray;
    background-color: #d48fb1
}

button.mat-flat-button.mat-primary.no {
    color: white;
    background-color: #808080;
}

button.mat-flat-button.mat-primary.warn {
    color: black;
    background-color: yellow;
}

.mat-form-field-infix {
    width: 1rem !important;    
}

.mat-menu-content {
    color: white;
    background-color: #f48fb1;
}

.notify_snackbar {
    font-size: 0.85em;
    font-weight: 400;
}

.notify_snackbar.error {
    color: red;
}

.notify_snackbar.warning {
    color: yellow;
}

.notify_snackbar.success {
    color: lightgreen;
}

.notify_snackbar.information {
    color: lightskyblue;
}

.notify_snackbar span.mat-button-wrapper {
    font-size: 0.85em;
    font-weight: 400;
    color: rgba(255,255,255,0.9);
}
